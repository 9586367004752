import Cookies from 'js-cookie';
import { bool, func, object, shape } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { compose } from 'redux';

import { useConfiguration } from '../../context/configurationContext';
import { ensureCurrentUser } from '../../util/data';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';

import { authenticationInProgress, login } from '../../ducks/auth.duck';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/ui.duck';

import { Footer, LayoutSingleColumn, Modal, NamedRedirect, Page } from '../../components';

import TopbarContainer from '../TopbarContainer/TopbarContainer';
import LoginForm from './LoginForm/LoginForm';

import HeroBoxes from '../LandingPage/HeroBoxes/HeroBoxes';
import css from './LoginPage.module.css';

// Tabs for SignupForm and LoginForm
export const AuthenticationForm = props => {
  const {
    submitLogin,
    loginError,
    authInProgress,
    onManageDisableScrolling,
    intl,
    isAuthenticated,
    currentUser,
    companyProfileData,
    talentProfileData
  } = props;

  const loginErrorMessage = (
    <div className={css.error}>
      <FormattedMessage id="LoginPage.loginFailed" />
    </div>
  );

  // eslint-disable-next-line no-confusing-arrow
  const errorMessage = (error, message) => (error ? message : null);
  const authError = errorMessage(loginError, loginErrorMessage);
  const [heroBoxesModalOpen, setHeroBoxesModalOpen] = useState(false);

  return (
    <div className={css.content}>
      <div className={css.formWrapper}>
        <h2 className={css.title}>
          <FormattedMessage id="LoginPage.loginTitle" />
        </h2>
        {authError}
        <LoginForm
          className={css.form}
          onSubmit={submitLogin}
          inProgress={authInProgress}
          onOpenHeroBoxesModal={() => {
            setHeroBoxesModalOpen(true);
          }}
        />
      </div>

      <Modal
        id="HeroBoxesModal"
        containerClassName={css.heroBoxesModal}
        isOpen={heroBoxesModalOpen}
        onClose={() => {
          setHeroBoxesModalOpen(false);
        }}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <HeroBoxes
            className={css.heroBoxes}
            intl={intl}
            isAuthenticated={isAuthenticated}
            currentUser={currentUser}
            companyProfileData={companyProfileData}
            talentProfileData={talentProfileData}
            isPopUp
          />
      </Modal>
    </div>
  );
};

const getAuthInfoFromCookies = () => {
  return Cookies.get('st-authinfo')
    ? JSON.parse(Cookies.get('st-authinfo').replace('j:', ''))
    : null;
};
const getAuthErrorFromCookies = () => {
  return Cookies.get('st-autherror')
    ? JSON.parse(Cookies.get('st-autherror').replace('j:', ''))
    : null;
};

export const LoginPageComponent = props => {
  const [authInfo, setAuthInfo] = useState(getAuthInfoFromCookies());
  const [authError, setAuthError] = useState(getAuthErrorFromCookies());
  const config = useConfiguration();

  useEffect(() => {
    // Remove the autherror cookie once the content is saved to state
    // because we don't want to show the error message e.g. after page refresh
    if (authError) {
      Cookies.remove('st-autherror');
    }
  }, []);

  const {
    authInProgress,
    currentUser,
    intl,
    isAuthenticated,
    location,
    loginError,
    scrollingDisabled,
    submitLogin,
    onManageDisableScrolling,
    companyProfileData,
    talentProfileData,
  } = props;

  // History API has potentially state tied to this route
  // We have used that state to store previous URL ("from"),
  // so that use can be redirected back to that page after authentication.
  const locationFrom = location.state?.from || null;
  const authinfoFrom = authInfo?.from || null;
  const from = locationFrom ? locationFrom : authinfoFrom ? authinfoFrom : null;

  const user = ensureCurrentUser(currentUser);
  const currentUserLoaded = !!user.id;

  // Already authenticated, redirect away from auth page
  if (isAuthenticated && from) {
    return <Redirect to={from} />;
  } else if (isAuthenticated && currentUserLoaded) {
    return <NamedRedirect name="LandingPage" />;
  }

  const marketplaceName = config.marketplaceName;
  const schemaTitle = intl.formatMessage({ id: 'LoginPage.schemaTitleLogin' }, { marketplaceName });
  const schemaDescription = intl.formatMessage(
    { id: 'LoginPage.schemaDescriptionLogin' },
    { marketplaceName }
  );

  return (
    <Page
      title={schemaTitle}
      scrollingDisabled={scrollingDisabled}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        name: schemaTitle,
        description: schemaDescription,
      }}
    >
      <LayoutSingleColumn
        mainColumnClassName={css.layoutWrapperMain}
        topbar={<TopbarContainer currentPage="LoginPage" />}
        footer={<Footer className={css.footer} />}
      >
        <AuthenticationForm
          submitLogin={submitLogin}
          authInProgress={authInProgress}
          loginError={loginError}
          onManageDisableScrolling={onManageDisableScrolling}
          intl={intl}
          isAuthenticated={isAuthenticated}
          currentUser={currentUser}
          companyProfileData={companyProfileData}
          talentProfileData={talentProfileData}
        />
      </LayoutSingleColumn>
    </Page>
  );
};

LoginPageComponent.defaultProps = {
  currentUser: null,
  loginError: null,
};

LoginPageComponent.propTypes = {
  authInProgress: bool.isRequired,
  currentUser: propTypes.currentUser,
  isAuthenticated: bool.isRequired,
  loginError: propTypes.error,
  scrollingDisabled: bool.isRequired,

  submitLogin: func.isRequired,
  onManageDisableScrolling: func.isRequired,

  // from withRouter
  location: shape({ state: object }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { isAuthenticated, loginError } = state.auth;
  const { currentUser } = state.user;

  return {
    authInProgress: authenticationInProgress(state),
    currentUser,
    isAuthenticated,
    loginError,
    scrollingDisabled: isScrollingDisabled(state),

    // profileData
    companyProfileData: state.CreateCompanyPage.profileData,
    talentProfileData: state.CreateTalentPage.profileData,
  };
};

const mapDispatchToProps = dispatch => ({
  submitLogin: ({ email, password }) => dispatch(login(email, password)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LoginPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(LoginPageComponent);

export default LoginPage;
